<template>
  <v-card flat>
    <v-card-title>Clear Application Cache</v-card-title>
    <v-card-text>
      <v-btn @click="clearCache">Clear Cache</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import DocAdmin from '@/store/data'

export default {
  name: 'ClearCache',
  methods: {
    async clearCache() {
      try {
        const response = await DocAdmin.app.clearCache.create()

        this.$store.dispatch('app/setSnackBar', {
          message: response.data.message,
          color: 'info'
        })
      } catch (error) {
        this.$store.dispatch('app/setSnackBar', {
          message: 'Failed to clear cache',
          color: 'error'
        })
      }
    }
  }
}
</script>
